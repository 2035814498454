<template>
  <v-form>
    <v-card
      max-width="800px"
      class="mt-4 mx-auto"
      :loading="loading"
      :disabled="loading"
    >
      <v-card-title v-text="$t('Venue')"/>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              :error-messages="nameErrors"
              :label="$t('name')"
              @blur="$v.item.name.$touch()"
              @input="$v.item.name.$touch()"
              required
              v-model="item.name"
            />
          </v-col>
        </v-row>
        <template v-if="item.address">
          <v-row>
            <v-col cols="12">
              <h3>{{ this.$t("Address") }}</h3>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                :error-messages="streetNrErrors"
                :label="$t('streetNr')"
                @blur="$v.item.address.streetNr.$touch()"
                @input="$v.item.address.streetNr.$touch()"
                v-model="item.address.streetNr"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                :error-messages="additionalErrors"
                :label="$t('additional')"
                @blur="$v.item.address.additional.$touch()"
                @input="$v.item.address.additional.$touch()"
                v-model="item.address.additional"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="4">
              <v-text-field
                :error-messages="zipCodeErrors"
                :label="$t('zipCode')"
                @blur="$v.item.address.zipCode.$touch()"
                @input="$v.item.address.zipCode.$touch()"
                required
                v-model="item.address.zipCode"
              />
            </v-col>
            <v-col cols="12" md="6" sm="8">
              <v-text-field
                :error-messages="cityErrors"
                :label="$t('city')"
                @blur="$v.item.address.city.$touch()"
                @input="$v.item.address.city.$touch()"
                v-model="item.address.city"
              />
            </v-col>
          </v-row>
        </template>
        <v-row v-if="refForm !== 'createForm'">
          <v-col cols="12" md="6">
            <div @dblclick="lngDisabled = false">
              <v-text-field
                :label="$t('Longitude') + ' (*)'"
                v-model.number="item.longitude"
                :disabled="lngDisabled"
                required
                @blur="lngDisabled = true"
              />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div @dblclick="latDisabled = false">
              <v-text-field
                :label="$t('Latitude') + ' (*)'"
                v-model.number="item.latitude"
                :disabled="latDisabled"
                @blur="latDisabled = true"
                required
              />
            </div>
          </v-col>
          <v-col cols="12" class="py-0 mt-n4 ">
            <span style="font-size:10px;">{{ $t("editDoubleClick") }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-combobox
              v-model="item.tags"
              :items="predefinedTags"
              chips
              clearable
              label="Tags"
              multiple
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="removeTag(item)"
                >
                  {{ item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" md="5">
            <v-file-input
              :rules="imageRules"
              :label="$t('venueImagePlaceHolder')"
              :placeholder="
                item.image ? item.image.name : $t('venueImagePlaceHolder')
              "
              v-model="uploadImage"
              accept="image/png, image/jpeg"
              prepend-icon="mdi-camera"
            />
          </v-col>
          <v-col cols="12" md="7">
            <template v-if="item.image">
              <v-img
                v-if="item.image.contentUrl"
                :src="documentEntryPoint + item.image.contentUrl"
                max-height="200"
                contain
              />
            </template>
          </v-col>
        </v-row>
        <v-divider class="my-8"/>
        <v-row>
          <v-col cols="12">
            <h3>{{ $t("VenueInternalData") }}</h3>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              :label="$t('venueRate')"
              v-model.number="item.rate"
              :error-messages="rateErrors"
              @blur="$v.item.rate.$touch()"
              @input="$v.item.rate.$touch()"
              prefix="€"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              :label="$t('flatRate')"
              v-model.number="item.flatRate"
              :error-messages="flatRateErrors"
              @blur="$v.item.flatRate.$touch()"
              @input="$v.item.flatRate.$touch()"
              prefix="€"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8" sm="8">
            <v-text-field
              :label="$t('contactPersonVenueInternal')"
              v-model="item.contactPerson"
              :error-messages="contactPersonErrors"
              @blur="$v.item.contactPerson.$touch()"
              @input="$v.item.contactPerson.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <v-checkbox
              :error-messages="enabledErrors"
              :label="$t('enabled')"
              @blur="$v.item.enabled.$touch()"
              @input="$v.item.enabled.$touch()"
              v-model="item.enabled"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="ml-auto" color="primary" @click="submitItem">{{ $t("Submit") }}</v-btn>
        <v-btn class="ml-sm-2 mainAccent lighten-3" dark @click="resetItem">{{ $t("Reset") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import has from "lodash/has";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import DocumentMixin from "@/mixins/DocumentMixin";

export default {
  name: "VenueForum",
  mixins: [validationMixin, DocumentMixin],
  components: {},
  props: {
    values: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {
      },
    },

    loading: {
      type: Boolean,
      required: true,
    },

    initialValues: {
      type: Object,
      default: () => {
      },
    },

    handleSubmit: {
      type: Function,
      required: false,
    },

    handleReset: {
      type: Function,
      required: false,
    },
    refForm: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lngDisabled: true,
      latDisabled: true,
      imageRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Image size should be less than 2 MB!",
      ],
      uploadImage: null,
      predefinedTags: [
        "barrierefreier Zugang",
        "barrierefreie Toiletten",
        "kostenfreie Parkplätze",
      ]
    };
  },
  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    nameErrors() {
      const errors = [];

      if (!this.$v.item.name.$dirty) {
        return errors;
      }

      has(this.violations, "name") && errors.push(this.violations.name);

      !this.$v.item.name.required && errors.push(this.$t("Field is required"));

      return errors;
    },
    rateErrors() {
      const errors = [];

      if (!this.$v.item.rate.$dirty) {
        return errors;
      }

      has(this.violations, "rate") && errors.push(this.violations.rate);

      !this.$v.item.rate.required && errors.push(this.$t("Field is required"));

      return errors;
    },
    flatRateErrors() {
      const errors = [];

      if (!this.$v.item.flatRate.$dirty) {
        return errors;
      }

      has(this.violations, "flatRate") && errors.push(this.violations.flatRate);

      !this.$v.item.flatRate.required &&
      errors.push(this.$t("Field is required"));

      return errors;
    },
    contactPersonErrors() {
      const errors = [];

      if (!this.$v.item.contactPerson.$dirty) {
        return errors;
      }

      has(this.violations, "contactPerson") && errors.push(this.violations.contactPerson);

      !this.$v.item.contactPerson.required &&
      errors.push(this.$t("Field is required"));

      return errors;
    },
    streetNrErrors() {
      const errors = [];

      if (!this.$v.item.address.streetNr.$dirty) {
        return errors;
      }

      has(this.violations, "streetNr") && errors.push(this.violations.streetNr);

      !this.$v.item.address.streetNr.required &&
      errors.push(this.$t("Field is required"));

      return errors;
    },
    additionalErrors() {
      const errors = [];

      if (!this.$v.item.address.additional.$dirty) {
        return errors;
      }
      has(this.violations, "additional") &&
      errors.push(this.violations.additional);

      return errors;
    },
    zipCodeErrors() {
      const errors = [];

      if (!this.$v.item.address.zipCode.$dirty) {
        return errors;
      }
      has(this.violations, "zipCode") && errors.push(this.violations.zipCode);
      !this.$v.item.address.zipCode.required &&
      errors.push(this.$t("Field is required"));

      return errors;
    },
    cityErrors() {
      const errors = [];

      if (!this.$v.item.address.city.$dirty) {
        return errors;
      }
      has(this.violations, "city") && errors.push(this.violations.city);
      !this.$v.item.address.city.required &&
      errors.push(this.$t("Field is required"));

      return errors;
    },
    enabledErrors() {
      const errors = [];

      if (!this.$v.item.enabled.$dirty) {
        return errors;
      }

      has(this.violations, "enabled") && errors.push(this.violations.enabled);
      return errors;
    },
    violations() {
      return this.errors || {};
    },
  },
  methods: {
    submitItem() {
      if (this.handleSubmit) {
        this.handleSubmit();
      }
    },
    resetItem() {
      if (this.handleReset) {
        this.handleReset();
      }
    },
    removeTag(item) {
      this.item.tags.splice(this.item.tags.indexOf(item), 1)
      this.item.tags = [...this.item.tags]
    },
  },
  validations: {
    item: {
      name: {
        required,
      },
      rate: {
        required,
      },
      flatRate: {
        required,
      },
      contactPerson: {
        required,
      },
      address: {
        streetNr: {
          required,
        },
        additional: {},
        zipCode: {
          required,
        },
        city: {
          required,
        }
      },
      enabled: {},
    },
  },
};
</script>
