<template>
  <v-card outlined tile class="fill-height" :loading="!notesLoaded">
    <v-card-title>
      {{ title }}
      <v-spacer/>
      <v-btn color="primary" small @click="createOrEditHandler(null)">
        {{ $t('AddNote') }}
      </v-btn>
      <NoteDialog
        v-model="showDialogForm"
        :note-item="formItem"
        :handle-created="onCreated"
        :handle-updated="onUpdated"
        :show-handle="showDialogForm"
        :title="$t('AddNote')"
        @close="dialogControl(true)"
      />
    </v-card-title>
    <v-card-text>
      <template v-if="notesLoaded && noteObjects.length > 0">
        <v-row>
          <v-col col="12" md="6" v-for="note in noteObjects" :key="note.id">
            <NoteCard
              :item="note"
              :handle-deleted="deleteNoteHandler"
              @showEdit="createOrEditHandler"
            />
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <p>Keine Daten verfügbar</p>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
import NoteDialog from "@/components/note/Dialog";
import NoteCard from "@/components/note/Card";
import remove from "lodash/remove";

export default {
  name: 'NoteListAndAdd',
  components: {
    NoteDialog,
    NoteCard
  },
  props: {
    notes: {
      type: Array,
      default: () => []
    },
    defaultItemProps: {
      type: Object,
      required: true,
    },
    showMessage: {
      type: Function,
      required: true
    },
    objectType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: function () {
        return this.$t('Notes')
      }
    }
  },
  data() {
    return {
      showDialogForm: false,
      formItem: {},
      loadingNotes: [],
    };
  },
  computed: {
    ...mapFields('note', {
      noteIds: 'allIds',
      deletedNoteItem: 'deleted',
    }),
    ...mapGetters('note', {findNote: 'find'}),
    noteObjects() {
      return this.notes.map((itemId) => {
        if (!this.noteIds.includes(itemId) && !this.loadingNotes.includes(itemId)) {
          this.loadingNotes.push(itemId);
          this.retrieveNote(decodeURIComponent(itemId));
        }
        return this.findNote(itemId);
      }).filter(e => e);
    },
    notesLoaded() {
      return (this.notes.length === this.noteObjects.length);
    },
  },
  methods: {
    ...mapActions('note', {
      retrieveNote: 'load',
      deleteNoteItem: 'del',
      updateNote: 'update',
    }),
    onCreated(item) {
      if (item[this.objectType] !== null) {
        this.notes.push(item['@id']);
      }
    },
    onUpdated() {

    },
    createOrEditHandler(expenseItem) {
      this.formItem = expenseItem ?? this.defaultItemProps;
      this.dialogControl();
    },
    dialogControl(close = false) {
      this.showDialogForm = !close;
    },
    deleteNoteHandler(item) {
      this.deleteNoteItem(item);
    },
  },
  watch: {
    deletedNoteItem() {
      var cleanedNotes = remove(this.notes, (uid) => {
        return !(uid === this.deletedNoteItem['@id']);
      });
      this.$emit('updateNotes', cleanedNotes);
      this.showMessage(`Note deleted.`);
    },
  },
}
</script>
