<template>
  <div class="pb-5">
    <Toolbar :handle-reset="resetForm" />
    <VenueForm
      :errors="violations"
      :values="item"
      ref="updateForm"
      refForm="updateForm"
      :handle-reset="resetForm"
      :handle-submit="onUpdateVenueForm"
      :loading="isLoading"
      v-if="item"
    />
    <v-card max-width="800px" class="mt-4 mx-auto">
      <v-card-title>
        {{ $t('ContactsPublic') }}
      </v-card-title>
      <v-card-text>
        <ContactList
          :contact-objects="contactObjects"
          :contacts-server-length="contactObjectsLength"
          :handle-created="onContactCreated"
          :handle-deleted="onContactDeleted"
          :tenant-seminar-settings-id="item['@id']"
        />
      </v-card-text>
    </v-card>
    <v-card max-width="800px" class="mt-4 mx-auto">
      <NoteListAndAdd
        :default-item-props="{ venue: this.item['@id'] }"
        :notes="this.item.notes"
        object-type="venue"
        :show-message="showMessage"
        :title="$t('NotesPrivate')"
        @updateNotes="updateNotesHandler"
      />
    </v-card>
    <Loading :visible="isLoading || deleteLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import VenueForm from '../../components/venue/Form.vue';
import Loading from '../../components/Loading';
import Toolbar from '../../components/Toolbar';
import UpdateMixin from '../../mixins/UpdateMixin';
import TopicMixin from '@/mixins/TopicMixin';
import NotificationMixin from '@/mixins/NotificationMixin';
import NoteListAndAdd from '../../components/note/ListAndAdd';
import ContactList from '@/components/contact/ContactList';
import ContactMixin from '@/mixins/ContactMixin';
import DocumentMixin from '../../mixins/DocumentMixin';

const servicePrefix = 'Venue';

export default {
  name: 'VenueUpdate',
  servicePrefix,
  mixins: [UpdateMixin, TopicMixin, NotificationMixin, ContactMixin, DocumentMixin],
  data() {
    return {
      updateMessage: this.$t('VenueUpdateSuccess'),
      deleteMessage: this.$t('VenueDeleteSuccess'),
      updateRoute: null,
      selected: [],
      headers: [
        { text: this.$i18n.t('name'), value: 'name' },
        { text: this.$i18n.t('enabled'), value: 'enabled' },
        {
          text: this.$i18n.t('Actions'),
          value: 'action',
          sortable: false
        }
      ],
      mail_type: 'mail'
    };
  },
  components: {
    Loading,
    Toolbar,
    VenueForm,
    NoteListAndAdd,
    ContactList
  },
  computed: {
    ...mapFields('venue', {
      deleteLoading: 'isLoading',
      isLoading: 'isLoading',
      error: 'error',
      deleted: 'deleted',
      updated: 'updated',
      violations: 'violations',
      totalItems: 'totalItems',
      resetList: 'resetList'
    }),
    ...mapGetters('venue', ['find']),
    contactObjects() {
      if (Array.isArray(this.item.contacts) === false) {
        return [];
      }
      return this.item.contacts.map((contactId) => this.resolveContact(contactId)).filter((contact) => contact);
    },
    contactObjectsLength() {
      if (Array.isArray(this.item.contacts) === false) {
        return 0;
      }
      return this.item.contacts.length;
    }
  },
  methods: {
    ...mapActions('venue', {
      deleteItem: 'del',
      retrieve: 'load',
      update: 'update',
      getPage: 'fetchAll'
    }),
    editHandler(item) {
      this.$router.push({
        name: `${this.$options.servicePrefix}Update`,
        params: { id: item['@id'] }
      });
      this.retrieve(item['@id']);
    },
    deleteHandler(item) {
      this.deleteItem(item);
    },
    deleteWatcher(deleted) {
      if (!deleted) {
        return;
      }
      this.showMessage(this.deleteMessage);
      this.retrieve(deleted.parent);
    },
    updateNotesHandler(updatedNotes) {
      this.item.notes = updatedNotes;
    },
    onContactCreated(createdContact) {
      this.item.contacts.push(createdContact['@id']);
    },
    onContactDeleted(deletedContact) {
      this.item.contacts.splice(this.item.contacts.indexOf(deletedContact['@id']), 1);
    },
    onUpdateVenueForm() {
      if (this.isValidForm()) {
        if (this.$refs.updateForm.uploadImage) {
          this.uploadDocument(this.$refs.updateForm.uploadImage);
        } else {
          this.onSendForm();
        }
      }
    }
  },
  watch: {
    uploadedDocument() {
      if (!this.uploadedDocument) {
        return;
      }
      this.item.image = this.uploadedDocument['@id'];
      this.onSendForm();
    }
  }
};
</script>
