<template>
  <v-dialog v-model="showDialogForm" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('New Note') }}</span>
      </v-card-title>
      <v-card-text>
        <NoteForm
          :errors="violations"
          :values="item"
          :loading="isLoading"
          ref="noteDialogForm"/>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="showDialogForm = false">{{ $t('Close') }}</v-btn>
        <v-spacer/>
        <v-btn class="mainAccent lighten-3" dark @click="resetForm">{{ $t('Reset') }}</v-btn>
        <v-btn color="primary" @click="sendForm">{{ $t('Submit') }}</v-btn>
      </v-card-actions>
    </v-card>
    <Loading :visible="isLoading"/>
  </v-dialog>
</template>

<script>
import {createHelpers} from 'vuex-map-fields';
import NoteForm from './Form';
import NotificationMixin from '../../mixins/NotificationMixin';
import Loading from "@/components/Loading";
import {mapActions, mapGetters} from "vuex";
import DialogMixin from "@/mixins/DialogMixin";

const servicePrefix = 'Note';

const {mapFields} = createHelpers({
  getterType: 'note/getField',
  mutationType: 'note/updateField'
});

export default {
  name: "NoteDialog",
  servicePrefix,
  mixins: [NotificationMixin, DialogMixin],
  components: {
    Loading,
    NoteForm
  },
  props: {
    value: Boolean,
    handleCreated: {
      type: Function,
      required: false
    },
    handleUpdated: {
      type: Function,
      required: false
    },
    noteItem: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      item: {},
      createMessage: this.$t("successfullyCreated"),
      updateMessage: this.$t("successfullyUpdated"),
      deleteMessage: this.$t("successfullyDeleted"),
    }
  },
  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations', 'updated', 'byId']),
    ...mapGetters('note', ['find']),
    showDialogForm: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
  },
  methods: {
    ...mapActions('note', {
      create: 'create',
      update: 'update',
      resetData: 'resetData',
      retrieve: 'load'
    }),
    sendForm() {
      if (this.isValidForm()) {
        let model = this.$refs.noteDialogForm.$v.item.$model;
        if (this.handleUpdated && model['@id']) {
          this.update(model);
        }
        if (this.handleCreated && !model['@id']) {
          this.create(model);
        }
      }
    },
    reset() {
      this.$refs.noteDialogForm.$v.$reset();
    },
    resetForm() {
      this.item = {...this.noteItem};
      if (this.$refs['noteDialogForm']) {
        this.$refs.noteDialogForm.$v.$reset();
      }
    },
    isValidForm() {
      this.$refs.noteDialogForm.$v.$touch();
      return !this.$refs.noteDialogForm.$v.$invalid;
    },
  },
  watch: {
    noteItem() {
      this.item = {...this.noteItem};
      this.resetForm();
    },
    created(created) {
      if (!created) {
        return;
      }
      if (!this.elementForDialog(created, this.item)) {
        return;
      }
      this.showMessage(this.createMessage);
      if (this.handleCreated) {
        this.handleCreated(this.created);
        this.showDialogForm = false;
      }
    },
    updated(updated) {
      if (!this.elementForDialog(updated, this.item)) {
        return;
      }
      this.showMessage(this.updateMessage);
      if (this.handleUpdated) {
        this.handleUpdated(this.updated);
        this.showDialogForm = false
      }
    },
    error(message) {
      message && this.showError(message);
    },
  },
  created() {
    this.item = {...this.noteItem};
  }
}
</script>
