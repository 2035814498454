<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <InputEditor
            v-model="item.content"
            :error-messages="contentErrors"
            :label="$t('description')"
            :required="true"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import has from 'lodash/has';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import InputEditor from "@/components/InputEditor";

export default {
  name: 'NoteForm',
  mixins: [validationMixin],
  components: {
    InputEditor
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: () => {
      }
    },
    initialValues: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      content: null,
    };
  },
  computed: {
    item() {
      return this.initialValues || this.values;
    },
    //region formErrors
    contentErrors() {
      const errors = [];
      if (!this.$v.item.content.$dirty) {
        return errors;
      }
      has(this.violations, 'content') && errors.push(this.violations.content);
      !this.$v.item.content.required && errors.push(this.$t('Field is required'));
      return errors;
    },
    violations() {
      return this.errors || {};
    },
    //endregion
  },
  validations: {
    item: {
      content: {
        required,
      },
    }
  },
}
</script>
