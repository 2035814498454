<template>
  <div>
    <v-card class="mt-5" color="yellow lighten-4">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <div v-html="item.content"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" small text @click.prevent="dialogControl(item)" v-if="hasRole('ROLE_UPDATE_NOTE')">
          {{ $t('Edit') }}
        </v-btn>
        <v-btn
          v-if="handleDeleted && hasRole('ROLE_DELETE_NOTE')"
          @click="confirmDelete = true"
          small class="red" dark color="danger"
        >
          {{ $t('Delete') }}
        </v-btn>
        <ConfirmDelete
          :handle-delete="del"
          :visible="confirmDelete"
          @close="confirmDelete = false"
          v-if="handleDeleted && hasRole('ROLE_DELETE_NOTE')"
        />
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>

import ConfirmDelete from '../ConfirmDelete';

export default {
  name: 'Card',
  components: {ConfirmDelete},
  props: {
    item: {
      type: Object,
      required: true
    },
    handleDeleted: {
      type: Function,
      required: false
    },
  },
  data() {
    return {
      confirmDelete: false,
    }
  },
  methods: {
    dialogControl(module) {
      this.$emit('showEdit', module)
    },
    del() {
      this.handleDeleted(this.item);
      this.confirmDelete = false;
    },
  },
};
</script>
